<template>
  <div>
    <Hero :visible.sync="visible" :show-cutout="false" class="pb-56 pt-28">
      <b-container class="mt-20">
        <b-row>
          <b-col lg="10" offset-lg="1" class="text-center">
            <h1
              class="text-4xl text-md-6xl text-lg-8xl text-white font-weight-black font-realist tracking-tight leading-none mb-md-8 mb-4"
            >
              About True360
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <b-container class="mt-n32 mb-24">
        <b-row>
          <b-col md="10" offset-md="1" lg="8" offset-lg="2">
            <div class="bg-white rounded shadow p-12">
              <h2 class="mb-4">
                True360 is part of the ACV network of brands, adding trust and
                transparency to the auto industry.
              </h2>
              <p>
                Expanding on ACV’s roster of highly trained inspectors, True360
                vehicle inspectors perform nationwide inspections for our
                True360 dealer partners, automotive manufactures and lessees
                nearing lease end. ACV’s True360 inspections examine the
                cosmetic and structural condition of a vehicle by identifying
                prior repairs or existing cosmetic damage to exterior body
                parts, and provides a structural inspection. The results are
                populated into an easy to understand report with an explanation
                of findings and supporting photos. True360 gives complete
                transparency to dealer partners and their customers.
              </p>

              <p>
                Together, ACV’s network of brands work seamlessly to provide
                added transparency to the auto industry. ACV’s offerings include
                ACV Auctions, ACV Capital, ACV Transportation.
              </p>

              <h2 class="mt-8 mb-4">Mission, Vision, and Values</h2>
              <p>
                At ACV, we believe in living our values every day and that
                success is all about providing trust and transparency to our
                customers. This is why we hire the best people and empower them
                to do what they believe is right and fair. Our team members are
                extremely hardworking, striving to improve our customer
                experience every day. By investing substantially in technology,
                we help our customers make smarter decisions and grow their
                businesses. With calm persistence, we constantly innovate and
                challenge the status quo. Our objectives are simple -- we want
                to be known beyond our industry for our world class service, and
                we want to be, hands down, the most trusted partner for our
                customers.
              </p>

              <h2 class="mt-8 mb-4">Our Values</h2>
              <ul>
                <li>
                  We provide trust and transparency in everything we do. We
                  empower our people to make decisions that benefit our
                  customers and the Company.
                </li>
                <li>
                  Our foundation is our people. We respect and listen to our
                  people, and we care for their well-being.
                </li>
                <li>
                  We create positive experiences for all of our customers,
                  striving every day to be famous for our service.
                </li>
                <li>
                  Calm persistence is how we operate, showing resilience and
                  persistence in the face of adversity.
                </li>
                <li>
                  We never settle for where we are today. Continually evolving
                  is part of our DNA.
                </li>
              </ul>

              <h2 class="mt-8 mb-4">Our Mission</h2>
              <p>
                We are on a mission to fundamentally change the wholesale
                automotive industry by providing a level of trust and
                transparency that was once unimaginable. We will continue to
                raise the bar every day by investing in our people and
                technology to help our customers succeed.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import Hero from "@/components/global/Hero";

export default {
  name: "LearnMore",
  components: {
    Hero
  },
  data() {
    return {
      visible: true
    };
  }
};
</script>
